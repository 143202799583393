<template>
  <div
    :class="[
      'w-full h-full group/status',
      { 'cursor-default': disabled },
    ]"
  >
    <TaskMovePopover
      v-model:open="open"
      :tasks="[task]"
      :trigger-class="triggerClass"
      button-class="px-2 py-1.5 max-h-8"
      @update:open="emit('update:open', $event)"
    >
      <div
        :class="[
          'w-full h-full  cursor-pointer',
        ]"
      >
        <div class="flex items-center gap-2 justify-between" data-test="menu-item">
          <div class="flex items-center gap-2">
            <div
              :style="{
                backgroundImage: `url(${getBackgroundUrl(
                  board,
                  BackgroundImageQuality.THUMB
                )})`,
              }"
              class="size-5 rounded-md border bg-center bg-cover min-w-5"
            />
            <div class="leading-normal gap-2 text-xs">
              <span class="line-clamp-1 break-all">{{ board.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </TaskMovePopover>
  </div>
</template>

<script setup lang="ts">
import type { TaskItem } from '#task/types'
import { BackgroundImageQuality } from '#board/constant'
import type { TaskFieldRef } from '#field/types'

defineOptions({
  inheritAttrs: false,
})
const props = defineProps({
  task: {
    type: Object as PropType<TaskItem>,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  triggerClass: {
    type: Function as PropType<(open: boolean) => string>,
  },
})

const emit = defineEmits(['update:open'])

const board = computed(() => {
  return readBoardFromCache(props.task.boardId)
})

const open = ref(false)

defineExpose<TaskFieldRef>({
  open: () => {
    open.value = true
  },
  close: () => {
    open.value = false
  },
  delete: () => {
    // Do nothing
  },
})
</script>
